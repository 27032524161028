import { useEffect, useState } from "react";
import './App.css';
import { Button, Card, Col, Container, Image, Modal, ProgressBar, Row } from "react-bootstrap";
import BackArrow from './images/back.svg'
import Refresh from './images/refresh.svg';
import Delete from './images/delete.svg';
import Home from './images/home.svg';
import BingoImage from './images/bingo.svg';
import GalleryImage from './images/gallery.svg';
import UploadImage from './images/upload.svg';
import Uploady, { UPLOADER_EVENTS, useItemProgressListener, useUploady } from "@rpldy/uploady";

function App() {
  enum Screen {
    Home,
    Bingo,
    Gallery
  }

  const [card, setCard] = useState<{ tasks: { name: string, description: string, image: string | null }[][], gallery: string[] }>({ tasks: [], gallery: [] });
  const [selected, setSelected] = useState<{ col?: number, row?: number, index?: number } | null>(null);
  const [dirty, setDirty] = useState<boolean>(true);
  const [screen, setScreen] = useState<Screen>(Screen.Home);

  function selectTask(row: number, col: number) {
    setSelected({ row, col });
  }

  function selectIndex(i: number) {
    setSelected({ index: i });
  }

  function getSelectedImage() {
    if (!selected) return null;
    if (selected.index !== undefined) return card.gallery[selected.index]
    if (selected.row !== undefined && selected.col !== undefined) return card.tasks[selected.row][selected.col].image;
  }

  function back() {
    if (selected) {
      unselect();
    } else {
      setScreen(Screen.Home);
    }
  }

  function unselect() {
    setSelected(null);
  }

  function refresh() {
    setDirty(true);
  }

  const cardKey = new URLSearchParams(window.location.search).get('key');
  useEffect(() => {
    if (cardKey) {
      fetch('https://locu.freeddns.org/api/cards/' + cardKey).then(res => res.json()).then(response => {
        var data: { tasks: { name: string, description: string, image: string | null }[][], gallery: string[] } = { tasks: [], gallery: response.gallery };
        response.tasks.forEach(
          (element: any) => {
            if (!data.tasks[element.row]) {
              data.tasks[element.row] = [];
            }
            data.tasks[element.row][element.col] = { name: element.name, description: element.description, image: element.image };
          }
        )
        setCard(data);
        setDirty(false);
      });
    }
  }, [cardKey, selected, dirty]);

  const [showDelete, setShowDelete] = useState(false);

  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = () => setShowDelete(true);
  const deleteImage = () => {
    if (selected)
      fetch('https://locu.freeddns.org/api/images/' + getSelectedImage(), { method: 'DELETE', }).then(() => {
        setShowDelete(false);
        if (selected.row !== undefined) {
          refresh();
        } else {
          unselect();
        }
      });
  }

  const Placeholder = () => {
    const uploady = useUploady();

    uploady.on(UPLOADER_EVENTS.BATCH_FINISH, refresh);

    const onClick = () => {
      uploady.showFileUpload();
    };

    return <Image src={UploadImage} onClick={onClick} fluid />;
  }

  const Placeholder2 = () => {
    const uploady = useUploady();

    uploady.on(UPLOADER_EVENTS.BATCH_FINISH, refresh);

    const onClick = () => {
      uploady.showFileUpload();
    };

    return <Image src={UploadImage} onClick={onClick} style={{ maxWidth: '20%' }} thumbnail />;
  }

  const Progress = () => {
    const progressData = useItemProgressListener();
    if (progressData && progressData.completed) {
      const now = progressData.completed;
      return <ProgressBar now={now} label={`${now}%`} />;
    }
    return null;
  }

  const filter = (file: File | string, index: number, files: File[] | string[]) => {
    if (file instanceof File) {
      return file.type.startsWith('image/');
    }
    return true;
  };

  var content;
  switch (screen) {
    default:
    case Screen.Home:
      content = <>
        <Row>
          <Col><Image src={BingoImage} onClick={() => setScreen(Screen.Bingo)} /></Col>
          <Col><Image src={GalleryImage} onClick={() => setScreen(Screen.Gallery)} /></Col>
        </Row>
      </>;
      break;
    case Screen.Bingo:
      content = (selected && selected.row !== undefined && selected.col !== undefined)
        ? <><Row className="py-2">
          <Col className="text-center">
            <h1>Finde einen Gast, welcher</h1>
          </Col>
        </Row>
          <Row>
            <Col className="text-center">
              <h2>{card.tasks[selected.row][selected.col].description}</h2>
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              {card.tasks[selected.row][selected.col].image
                ? <Image src={'https://locu.freeddns.org/api/images/' + card.tasks[selected.row][selected.col].image} className="p-2" fluid />
                : <Uploady destination={{ url: "https://locu.freeddns.org/api/images/", params: { row: selected.row, col: selected.col, card: cardKey } }} fileFilter={filter}>
                  <Placeholder />
                  <Progress />
                </Uploady>}
            </Col>
          </Row>
          <Row className="py-2">
            <Col className="text-center">
              <h2>{card.tasks[selected.row][selected.col].description}</h2>
            </Col>
          </Row></>
        : card.tasks.map(
          (row: any, i: number) => <Row key={'row' + i} xs={row.length}>{row.map(
            (task: any, j: number) =>
              <Col key={'col' + i + j} className="p-1 d-flex align-items-stretch" onClick={() => selectTask(i, j)} >
                <Card key={'col' + i + j} onClick={() => selectTask(i, j)} >
                  <Card.Img variant="top" src={task.image ? 'https://locu.freeddns.org/api/images/' + task.image : UploadImage} />
                  <Card.Footer className='text-center mt-auto p-0'>{task.name}</Card.Footer>
                </Card >
              </Col>
          )}</Row>);
      break;
    case Screen.Gallery:
      content = (selected && selected.index !== undefined)
        ? <Row>
          <Col className="text-center">
            <Image src={'https://locu.freeddns.org/api/images/' + card.gallery[selected.index]} className="p-2" fluid />
          </Col>
        </Row>
        : <Row><Col>
          <Uploady destination={{ url: "https://locu.freeddns.org/api/images/", params: { card: cardKey } }} fileFilter={filter} grouped>
            <Placeholder2 />
          </Uploady>
          {card.gallery.map((image: string, i: number) => <Image key={'gallery-' + i} src={'https://locu.freeddns.org/api/images/' + image} style={{ maxWidth: '20%' }} onClick={() => selectIndex(i)} thumbnail />)}
        </Col> </Row>;
      break;
  }

  return (
    <Container fluid ><Row className="py-2">
      <Col className="text-center"><Image src={Home} onClick={() => { unselect(); setScreen(Screen.Home) }} className="icon" /></Col>
      <Col className="text-center">{(screen !== Screen.Home) && <Image src={BackArrow} onClick={back} className="icon" />}</Col>
      <Col className="text-center">{selected && <Image src={Delete} onClick={handleShowDelete} className="icon" />}</Col>
      <Col className="text-center"><Image src={Refresh} onClick={refresh} className="icon" /></Col>
    </Row>
      {cardKey ? <>
        {content}
        < Modal show={showDelete} onHide={handleCloseDelete} >
          <Modal.Header closeButton>
            <Modal.Title>Photo löschen</Modal.Title>
          </Modal.Header>
          <Modal.Body>Photo wirklich löschen?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDelete}>
              Abbrechen
            </Button>
            <Button variant="primary" onClick={deleteImage}>
              Löschen
            </Button>
          </Modal.Footer>
        </Modal ></>
        : <div>Fehlerhafter Link</div>
      } </Container>
  )
}

export default App;
